<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <a-form-item label="仓库名称" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input
          v-decorator="['name', { rules: [{ required: true, message: '仓库名称不能为空' }] }]"
          placeholder="请输入仓库名称"
        ></a-input>
      </a-form-item>
      <a-form-item label="所属部门" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select
          v-decorator="['department.id', { rules: [{ required: true, message: '所属部门不能为空' }] }]"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="orgTree"
          allowClear
          placeholder="请选择，默认当前登录用户所在部门"
        ></a-tree-select>
      </a-form-item>
      <a-form-item label="仓库状态" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-select
          allowClear
          placeholder="请选择状态"
          v-decorator="['status', { rules: [{ required: true, message: '状态不能为空' }] }]"
        >
          <a-select-option v-for="item in statusList" :key="item.value" :value="item.value" :label="item.label">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="所在位置" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select
          v-decorator="['area.id', { rules: [{ required: true, message: '所在位置不能为空' }] }]"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="areaTree"
          allowClear
          placeholder="请选择省市县"
        ></a-tree-select>
      </a-form-item>
      <a-form-item label="具体地址" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['location']" placeholder="请输入具体地址"></a-input>
      </a-form-item>
      <a-form-item label="经度：" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input placeholder="请输入经度" v-decorator="['longitude']"></a-input>
      </a-form-item>
      <a-form-item label="纬度：" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input placeholder="请输入纬度" v-decorator="['latitude']"></a-input>
      </a-form-item>
      <a-form-item label="上传图片" :label-col="labelCol" :wrapper-col="wrapperCol">
        <qiniu-image-upload
          ref="qiniuImageRef"
          @getImageData="getImageData"
          :document-dir-id="1"
          :multiSelection="true"
          type="edit"
          :items="formItem.images"
        ></qiniu-image-upload>
      </a-form-item>
      <a-form-item label="上传文件" :label-col="labelCol" :wrapper-col="wrapperCol">
        <qiniu-file-upload
          ref="qiniuFileRef"
          @getFileData="getFileData"
          :document-dir-id="2"
          :multiSelection="true"
          type="edit"
          :items="formItem.files"
        ></qiniu-file-upload>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
import { depotFormVO } from './common/depotFormVO'
import entityCURDCallback from '../../common/mixins/entityCURDCallback'
import SERVICE_URLS from '@/api/service.url'
import { statusList } from './common/common'
import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'
import QiniuFileUpload from '@/components/QiniuUpload/QiniuFileUpload'
export default {
  name: 'DepotForm',
  mixins: [entityCURDCallback],
  components: { QiniuImageUpload, QiniuFileUpload },
  data() {
    return {
      entityBaseUrl: SERVICE_URLS.equipment2.depotApi,
      initFormItem: depotFormVO(),
      showFormItem: false,
      statusList: statusList(),
      orgTree: [],
      areaTree: [],
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser
    },
  },
  mounted() {
    this.getOrgTree()
    this.getAreaTree()
    this.form.setFieldsValue({
      'department.id': this.currentDeptId().organizationId + '',
    })
    this.setDeptId()
  },
  methods: {
    setDeptId() {
      let deptId = null
      // 获取当前部门
      if (this.currentUser) {
        if (this.currentUser.organizationJobs) {
          if (this.currentUser.organizationJobs.length) {
            deptId =  this.currentDeptId().organizationId + ''
          }
        }
      }
      if (deptId) {
        this.form.setFieldsValue({
          'department.id': deptId,
        })
      }
    },
    setFields(values) {
      Object.assign(this.formItem, values)
      values.images.forEach((item) => {
        this.formItem.imageIds.push(item.id)
      })
      /*文件初始化展示处理赋值Ids*/
      values.files.forEach((item) => {
        this.formItem.fileIds.push(item.id)
      })
      this.form.setFieldsValue({
        name: this.formItem.depotInfo.name,
        'department.id': this.formItem.depotInfo.department.id + '',
        status: this.formItem.depotInfo.status,
        longitude: this.formItem.depotInfo.longitude,
        latitude: this.formItem.depotInfo.latitude,
        'area.id': this.formItem.depotInfo.area.id + '',
        location: this.formItem.depotInfo.location,
      })
    },
    setVOFields(values) {
      this.formItem.depotInfo.name = values.name
      this.formItem.depotInfo.department.id = values.department.id
      this.formItem.depotInfo.status = values.status
      this.formItem.depotInfo.longitude = values.longitude
      this.formItem.depotInfo.latitude = values.latitude
      this.formItem.depotInfo.area.id = values.area.id
      this.formItem.depotInfo.location = values.location
    },
    addSuccessCallback(data) {
      this.form.setFieldsValue({
        'department.id': this.currentDeptId().organizationId + '',
      })
      // 添加成功之后Form内部可做的回调
    },
    addErrorCallback(e) {
      // 添加失败之后Form内部可做的回调
      // this.form.resetFields()
    },
    // editSuccessCallback (data) {
    //   // 修改成功之后Form内部可做的回调
    // },
    editErrorCallback(e) {
      // 修改失败之后Form内部可做的回调
      // this.form.resetFields()
    },
    //////////////////////////////////////////////////////
    getOrgTree() {
      this.$http(this, {
        url: SERVICE_URLS.organization.antTree,
        noTips: true,
        success: (data) => {
          this.orgTree = data.body
        },
      })
    },
    getAreaTree() {
      this.$http(this, {
        url: SERVICE_URLS.equipment2.depotApi.getAreaTree,
        noTips: true,
        success: (data) => {
          this.areaTree = data.body
        },
      })
    },
    /*上传图片回调方法*/
    getImageData(data) {
      this.formItem.imageIds = []
      data.forEach((item) => {
        this.formItem.imageIds.push(item.id)
      })
    },
    /*上传文件回调方法*/
    getFileData(data) {
      this.formItem.fileIds = []
      data.forEach((item) => {
        this.formItem.fileIds.push(item.id)
      })
    },
  },
}
</script>

<style scoped></style>
