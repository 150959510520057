/* eslint-disable no-extra-bind */

let handleDragStart
let handleDragOver
let handleDragEnter
let handleDragLeave
let handleDrag
let handleDragEnd
let handleDrop

const dragAndDrop = {
  bind: function (el, binding, vnode) {
    handleDragStart = function (e) {
      el.classList.add('dragging')
      e.dataTransfer.effectAllowed = 'move'
      // Need to set to something or else drag doesn't start
      e.dataTransfer.setData('text', '*')
      vnode.context.$emit('drag-start')
    }.bind(this)

    handleDragOver = function (e) {
      if (e.preventDefault) {
        // allows dropping
        e.preventDefault()
      }

      e.dataTransfer.dropEffect = 'move'
      el.classList.add('drag-over')
      vnode.context.$emit('drag-over')

      return false
    }.bind(this)

    handleDragEnter = function (e) {
      vnode.context.$emit('drag-enter')
      el.classList.add('drag-enter')
    }.bind(this)

    handleDragLeave = function (e) {
      vnode.context.$emit('drag-leave')
      el.classList.remove('drag-enter', 'drag-over')
    }.bind(this)

    handleDrag = function () {
      vnode.context.$emit('drag')
    }.bind(this)

    handleDragEnd = function (e) {
      el.classList.remove('dragging', 'drag-over', 'drag-enter')
      vnode.context.$emit('drag-end')
    }.bind(this)

    handleDrop = function (e) {
      e.preventDefault()
      if (e.stopPropagation) {
        // stops the browser from redirecting.
        e.stopPropagation()
      }

      // Don't do anything if dropping the same column we're dragging.
      vnode.context.$emit('drop')

      return false
    }.bind(this)

    // setup the listeners
    el.setAttribute('draggable', 'true')
    el.addEventListener('dragstart', handleDragStart, false)
    el.addEventListener('dragenter', handleDragEnter, false)
    el.addEventListener('dragover', handleDragOver, false)
    el.addEventListener('drag', handleDrag, false)
    el.addEventListener('dragleave', handleDragLeave, false)
    el.addEventListener('dragend', handleDragEnd, false)
    el.addEventListener('drop', handleDrop, false)
  },
  unbind: function (el) {
    // shut er' down
    el.classList.remove('dragging', 'drag-over', 'drag-enter')
    el.removeAttribute('draggable')
    el.removeEventListener('dragstart', handleDragStart)
    el.removeEventListener('dragenter', handleDragEnter)
    el.removeEventListener('dragover', handleDragOver)
    el.removeEventListener('dragleave', handleDragLeave)
    el.removeEventListener('drag', handleDrag)
  }
}

export default dragAndDrop